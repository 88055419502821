import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-vivid-svg-icon',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  template: `
    <style>
      mat-icon {
        height: 24px;
        width: 24px;
        & svg {
          & g > path {
            stroke: #737681;
          }
        }
      }
    </style>
    <mat-icon
      [svgIcon]="icon"
      aria-hidden="false"
      aria-label="Edit"
      [ngStyle]="customStyle"
      (click)="handleClick()"
    ></mat-icon>
  `,
})
export class VividSvgIconsComponent {
  @Output() clickEvent: EventEmitter<MouseEvent> =
    new EventEmitter<MouseEvent>();
  @Input() icon!: string;
  @Input() customStyle: any = null;

  /**
   * @param iconRegistry
   * @param sanitizer
   */
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    /* ---------- Outline: UI Standard ----------------*/
    iconRegistry.addSvgIcon(
      'pencil_outline',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg-health/outline/ui_standard/pencil.svg',
      ),
    );
    iconRegistry.addSvgIcon(
      'mobile_outline',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg-health/outline/ui_standard/mobile.svg',
      ),
    );
    iconRegistry.addSvgIcon(
      'exit_outline',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg-health/outline/ui_standard/exit.svg',
      ),
    );
    iconRegistry.addSvgIcon(
      'close_outline',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg-health/outline/ui_standard/close.svg',
      ),
    );
    iconRegistry.addSvgIcon(
      'check_outline',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg-health/outline/ui_standard/check.svg',
      ),
    );
    iconRegistry.addSvgIcon(
      'sync_clipboard_outline',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg-health/outline/ui_standard/sync_clipboard.svg',
      ),
    );
    iconRegistry.addSvgIcon(
      'trash_outline',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg-health/outline/ui_standard/trash.svg',
      ),
    );

    /* ---------- Outline: UI Standard ----------------*/
    iconRegistry.addSvgIcon(
      'doctor_radiology_outline',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg-health/outline/people/doctor_radiology.svg',
      ),
    );
    iconRegistry.addSvgIcon(
      'doctor_outline',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg-health/outline/people/doctor.svg',
      ),
    );

    /* ---------- Outline ----------------*/
    iconRegistry.addSvgIcon(
      'heart_monitor_search_outline',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg-health/outline/devices/heart_monitor_search.svg',
      ),
    );
    iconRegistry.addSvgIcon(
      'data_privacy_security_outline',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg-health/outline/security/data_privacy.svg',
      ),
    );
    iconRegistry.addSvgIcon(
      'autism',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg-health/outline/conditions/autism.svg',
      ),
    );
    iconRegistry.addSvgIcon(
      'public_health_outline',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg-health/outline/specialties/public_health.svg',
      ),
    );
  }

  public handleClick(): void {
    this.clickEvent.emit(new MouseEvent('click'));
  }
}
