export const environment = {
  production: false,
  cognito: {
    userPoolId: 'us-east-1_CqK54P9o4',
    userPoolWebClientId: '22714mm1kuptmpf441m2ue859j',
  },
  carePlanUrl: `${window.location.origin}/careplan/`,
  maxPasswordAttempts: 4,
  tenantApiBaseUrl: 'https://api.dev.meetvividhealth.com/tenantsvc/v1',
  notificationApiBaseUrl:
    'https://api.dev.meetvividhealth.com/notifsvc/v1/notification',
  supervisorWorkforceLink:
    `${window.location.origin}/practitionerhub/supervisor/workforce`,
  staticAssetsUrl: 'https://vividhealth-dev-assets.s3.amazonaws.com',
};
