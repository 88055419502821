export const isObjectEmpty = (objectName: {}) => {
  return (
    objectName &&
    Object.keys(objectName).length === 0 &&
    objectName.constructor === Object
  );
};

export const mergeArrayOfObjects = (
  array: any[],
  props: { index: string; value: string },
): any => {
  return array.reduce(
    (acc: any, next: any) => ({
      ...acc,
      [next[props.index]]: next[props.value],
    }),
    {},
  );
};
