import { HttpHeaders } from '@angular/common/http';

export const getHttpHeaders = (token: string | undefined = undefined) => {
  return {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      ...(token && { Authorization: `Bearer ${token}` }),
    }),
  };
};

export const parseToken = (token: string): any => {
  return atob(token.split('.')[1]);
};
