import { Component } from '@angular/core';
import { PhcAppService } from '@common/services/phcapp.service';

@Component({
  selector: 'app-logo',
  template: `<img alt="logo" src="{{ tenantLogoSrc }}" style="max-height: 70px" />`,
})
export class LogoComponent {

  public tenantLogoSrc: string;

  constructor(
    private phcappService: PhcAppService,
  ) {
    this.tenantLogoSrc = this.phcappService.tenant?.branding?.logoUrl;
  }
}
