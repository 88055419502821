import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserRoleTypesEnum } from '@common/enums/user-role-enum';
import { ITokens } from '@common/models/tokens.model';
import { parseToken } from '@common/utils/common.utils';
import { environment } from '@environments/environment';
import { CognitoService } from '@src/app/common/services/cognito.service';
import { PhcAppService } from '@src/app/common/services/phcapp.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  private _userLoggedIn!: boolean;
  public name: string = '';
  public initials: string = '';
  public emailAddress: string = '';

  public get userLoggedIn() {
    return this._userLoggedIn;
  }

  private _isSupervisor!: boolean;
  public get isSupervisor() {
    return this._isSupervisor;
  }

  constructor(
    private router: Router,
    private phcAppService: PhcAppService,
    private cognitoService: CognitoService
  ) {}

  async ngOnInit(): Promise<void> {
    const tokens: ITokens | null = this.verifyTokens();
    const user = await this.cognitoService.getUser();
    const parsedIdToken = tokens
      ? JSON.parse(parseToken(tokens.idToken))
      : null;
    if (user && tokens && user.username === parsedIdToken['cognito:username']) {
      this.setTokens(tokens);
      this.setUser(tokens);
      this.setUserRole();
      this.setName();
      this.setInitials();
      this.setEmail();
      this.setUserLoginState(true);
    } else {
      this.setUserRole();
      this.setUserLoginState(false);
    }
  }

  public async signOut() {
    await this.cognitoService.signOut();
    sessionStorage.clear();
    localStorage.clear();
    this._userLoggedIn = false;
    await this.navigateToLogIn();
  }

  public async navigateToMyAccount(): Promise<void> {
    await this.router.navigate(['/manage-account']);
  }

  public async navigateToWorkforce() {
    await this.router.navigate(['/supervisor/workforce']);
  }

  public navigateToDashboard(): void {
    if (this.userLoggedIn) {
      window.location.href = environment.carePlanUrl;
    }
  }

  public async navigateToLogIn(): Promise<void> {
    await this.router.navigate(['/account']);
  }

  private setRedirectUrl() {
    this.cognitoService.loginSuccessRedirectUrl = window.location.href;
    setTimeout(() => {
      sessionStorage.setItem('redirectUrl', window.location.href);
    }, 0);
  }

  private verifyTokens(): ITokens | null {
    const sessionStorageTokens: string | null =
      sessionStorage.getItem('userTokens');
    if (sessionStorageTokens) {
      return JSON.parse(sessionStorageTokens);
    }
    return null;
  }

  private setTokens(tokens: ITokens): void {
    if (!this.phcAppService.tokens) {
      this.phcAppService.tokens = tokens;
    }
  }

  private setUser(tokens: ITokens): void {
    if (!this.phcAppService.user) {
      this.phcAppService.user = tokens.idToken;
    }
  }
  private setUserLoginState(loginState: boolean): void {
    this._userLoggedIn = loginState;
  }

  private setUserRole(): void {
    if (this.phcAppService.user?.userRole) {
      this._isSupervisor =
        this.phcAppService.user.userRole.toLowerCase() ===
        UserRoleTypesEnum.supervisor;
    }
  }

  private setName(): void {
    this.name = `${this.phcAppService.user?.firstName} ${this.phcAppService.user?.lastName}`;
  }

  private setInitials(): void {
    const firstNameInitial = this.phcAppService.user?.firstName?.charAt(0);
    const lastNameInitial = this.phcAppService.user?.lastName?.charAt(0);
    this.initials = `${firstNameInitial?.toUpperCase()}${lastNameInitial?.toUpperCase()}`;
  }

  private setEmail(): void {
    this.emailAddress = this.phcAppService.user?.email;
  }
}
