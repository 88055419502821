import { Injectable } from '@angular/core';
import { ITenant } from '@src/app/common/models/tenant.model';
import { IUser } from '@common/models/user.models';
import { ITokens } from '@common/models/tokens.model';
import { parseToken } from '@common/utils/common.utils';

@Injectable({
  providedIn: 'root',
})
export class PhcAppService {
  private _tenant!: ITenant;
  public idToken!: string;
  private _user!: IUser;
  private _tokens!: ITokens;

  public get tenant(): ITenant {
    return this._tenant;
  }

  public set tenant(tenant: ITenant) {
    this._tenant = tenant;
  }

  public get tokens(): ITokens {
    return this._tokens;
  }

  public set tokens(tokens: ITokens) {
    this._tokens = tokens;
  }

  public get user(): IUser {
    return this._user;
  }

  public set user(token: string) {
    const userAttributes = JSON.parse(parseToken(token));
    this._user = {
      resourceId: userAttributes['custom:fhirResourceId'],
      tenantId: userAttributes['custom:tenantId'],
      firstName: userAttributes['given_name'],
      lastName: userAttributes['family_name'],
      title: userAttributes['custom:title'],
      email: userAttributes['email'],
      workplaceCode: userAttributes['custom:workplaceCode'],
      npi: userAttributes['custom:NPI'],
      phoneNumber: userAttributes['phone_number'],
      userRole: userAttributes['custom:userRole'],
      scopes: userAttributes['custom:scopes'],
      externalResourceId: userAttributes['custom:externalResourceId'],
      userName: userAttributes['cognito:username'],
    };
  }
}
